<template lang="pug">
  v-container#user-profile(fluid='' tag='section')
    v-snackbar(:timeout='5000' top='' right='' :color='snackbar.color' v-model='snackbar.show')
      | {{ snackbar.text }}
      v-btn(dark='' text='' @click.native='snackbar.show = false' icon='' style='background: transparent;')
        v-icon mdi-close
    v-card(v-if="!userStatus")
      v-toolbar(text='' color='primary' dark='')
        v-toolbar-title
          h4 Registro de clientes
          v-btn(icon="" dark="" style="position: absolute; top: 0px; right: 10px;" @click="$emit('closeModal')")
            v-icon.primary.rounded mdi-close
      v-card-text
        div(style="widht: 100% text-align: center; padding-top: 100px;")
          v-alert(type='error')
            | En este momento su cuenta se encuentra inactiva. Debe ponerse en contacto con su proovedor para verificar este error, de lo contrario por favor active su plan para seguir ingresando nuevos clientes, gracias.
        row
          v-col(cols='12' md="12").text-center
            //- v-btn.ml-2(min-width='0' text='' style='' @click="openLink('https://www.facebook.com/dental.prime.391/')")
              v-icon(color="black") mdi-facebook
            v-btn.ml-2(min-width='0' text=''  style="" @click="openLink('https://www.instagram.com/j2rtech/')")
              v-icon(color="black") mdi-instagram
            v-btn.ml-2(min-width='0' text='' style="" @click="openLink('https://wa.me/+593984530998')")
              v-icon(color="black") mdi-whatsapp
    v-card(v-if="userStatus")
      v-toolbar(text='' color='primary' dark='')
        v-toolbar-title
          h4 Registro de clientes
          v-btn(icon="" dark="" style="position: absolute; top: 0px; right: 10px;" @click="$emit('closeModal')")
            v-icon.primary.rounded mdi-close
      v-card-text
        v-row(justify='center')
          v-col(cols='12' md='12')
            v-form(ref='formClient' v-model='validClient' lazy-validation='' autocomplete="random-string")
              v-container.py-0
                v-row
                  v-col(cols="12" md="4" style="padding-top: 4px;")
                    v-select(return-object v-model='client.idType' :items="electronicbillingtables.tipoidentificacion" label="Tipo de identificación" style="padding-top: 20px;" item-text='texto')
                  v-col(cols='12' md='2' v-if="client.idType.codigo.toString() === '04'")
                    v-text-field.purple-input(:rules="requeridos" v-model="client.ide" label='Identificación' type='text' maxlength="13" @keypress="isNumber($event)")
                  v-col(cols='12' md='2' v-if="client.idType.codigo.toString() === '05'")
                    v-text-field.purple-input(:rules="requeridos" v-model="client.ide" label='Identificación' type='text' maxlength="10" @keypress="isNumber($event)")
                  v-col(cols='12' md='2' v-if="client.idType.codigo.toString() === '06' || client.idType.codigo.toString() === '07' || client.idType.codigo.toString() === '08'")
                    v-text-field.purple-input(:rules="requeridos" v-model="client.ide" label='Identificación' type='text' maxlength="20")
                  v-col(cols='12' md='6' v-if="client.idType.codigo.toString() !== '04'")
                    v-text-field.purple-input(@keyup="nameLastName = nameLastName.toUpperCase()" :rules="requeridos" v-model="nameLastName" label='Nombre Completo del Cliente' type='text' maxlength="150")
                  //- <!--v-col(cols='12' md='3' v-if="client.idType.codigo.toString() !== '04'")-->
                  //-   <!--v-text-field.purple-input(:rules="requeridos" v-model="client.lastName" label='Apellido del cliente' type='text' maxlength="150")-->
                  v-col(cols='12' md='6' v-if="client.idType.codigo.toString() === '04'")
                    v-text-field.purple-input(@keyup="client.businessName = client.businessName.toUpperCase()" :rules="requeridos" v-model="client.businessName" label='Razón Social' type='text' maxlength="300")
                  v-col(cols='12' md='4')
                    v-text-field.purple-input(label='Correo del cliente' v-model="client.mail" type='text' maxlength="150")
                  v-col(cols='12' md='4')
                    v-text-field.purple-input(v-model="client.cellphone" label='Teléfono Celular del cliente' type='text' maxlength="10" @keypress="isNumber($event)")
                  v-col(cols='12' md='4')
                    v-text-field.purple-input(v-model="client.phone" label='Teléfono del cliente' type='text' maxlength="10" @keypress="isNumber($event)")
                  v-col(cols='12' md='12')
                    v-text-field.purple-input(@keyup="client.address = client.address.toUpperCase()" :rules="requeridos" v-model="client.address" label='Dirección del cliente' type='text' maxlength="300")
                  v-col.text-center(cols='12' md="4" offset-md="8")
                    v-btn.mr-0.success-btn(text='' @click='saveClient' :loading="loadingSaveEdit")
                      | GUARDAR CLIENTE
</template>

<script>
  import * as functions from '../../../../functions'
  // import firebase from 'firebase'
  import firebase from 'firebase/app'
  import 'firebase/database'
  import 'firebase/auth'
  export default {
    props: {
      clientToEdit: {
        default: function () {
          return {
            idType: {
              codigo: '05',
              texto: 'CEDULA',
            },
            businessName: '',
            name: '',
            lastName: '',
            mail: '',
            address: '',
            ide: '',
            phone: '',
            cellphone: '',
            clientKey: '',
          }
        },
        type: Object,
      },
    },
    data () {
      return {
        nameLastName: '',
        emailRules: [
          v => !!v || 'Correo requerido',
          v => /\S+@\S+\.\S+/.test(v) || 'Email inválido',
        ],
        snackbar: {
          show: false,
          text: '',
          color: '',
        },
        client: {
          idType: {
            codigo: '05',
            texto: 'CEDULA',
          },
          businessName: '',
          name: '',
          lastName: '',
          mail: '',
          address: '',
          ide: '',
          phone: '',
          cellphone: '',
          clientKey: '',
        },
        loadingSaveEdit: false,
        validClient: true,
        requeridos: [
          value => !!value || 'Requerido',
        ],
        userStatus: true,
      }
    },
    computed: {
      clientLst () {
        return this.$store.state.client.lstClients
      },
      planLst () {
        return this.$store.state.suscriptionPlan.plan
      },
      electronicbillingtables () {
        return this.$store.state.facturacion.electronicbillingtables
      },
    },
    watch: {
      planLst () {
        if (this.clientLst.length >= this.planLst.clients) {
          this.userStatus = false
        } else {
          this.userStatus = true
        }
      },
      clientToEdit () {
        this.client = this.clientToEdit
        this.nameLastName = (this.client.name + ' ' + this.client.lastName).trim()
      },
    },
    mounted () {
      this.electronicbillingtables = this.$store.state.facturacion.electronicbillingtables
      this.client = this.clientToEdit
      this.nameLastName = (this.client.name + ' ' + this.client.lastName).trim()
      if (this.$store.state.client.lstClients.length >= this.$store.state.suscriptionPlan.plan.clients) {
        this.userStatus = false
      } else {
        this.userStatus = true
      }
    },
    methods: {
      // updateClientName () {
      //   this.client.idType = this.nameLastName
      //   this.client.ide = this.nameLastName
      // },
      isNumber: function (evt) {
        evt = (evt) || window.event
        var charCode = (evt.which) ? evt.which : evt.keyCode
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault()
        } else {
          return true
        }
      },
      openLink (link) {
        window.open(`${link}`, '_blank')
      },
      saveClient () {
        if (this.$refs.formClient.validate()) {
          let result = 0
          if (this.client.idType.codigo.toString() === '04') {
            result = functions.validIdentNumber(false, this.client.ide)
          } else if (this.client.idType.codigo.toString() === '05') {
            result = functions.validIdentNumber(true, this.client.ide)
          } else {
            result = 1
          }
          if (result.toString() === '1') {
            if (this.client.clientKey === '' || !this.client.clientKey) {
              const filterItem = this.clientLst.filter(item => {
                return item.ide === this.client.ide
              })
              if (filterItem.length > 0) {
                if (filterItem[0].id !== this.client.id) {
                  this.snackbar = {
                    show: true,
                    color: 'red',
                    text: 'El cliente ya está agregado.',
                  }
                  return
                }
              }
              this.client.clientKey = firebase.database().ref(`clients/${this.$store.state.user.user.uid}`).push().getKey()
            } else {
              const filterItem = this.clientLst.filter(item => {
                return item.ide === this.client.ide
              })
              if (filterItem.length > 0) {
                if (filterItem[0].id !== this.client.id) {
                  this.snackbar = {
                    show: true,
                    color: 'red',
                    text: 'El cliente ya está agregado.',
                  }
                  return
                }
              }
            }
            if (this.client.idType.codigo.toString() === '04') {
              this.client.completeData = this.client.businessName + ' | ' + this.client.ide + ' | ' + this.client.mail
              this.client.viewName = this.client.businessName
            } else {
              const arrStrNameLastName = this.nameLastName.trim().split(' ')
              if (arrStrNameLastName.length > 0) {
                if (arrStrNameLastName.length === 1) {
                  this.client.name = arrStrNameLastName[0]
                  this.client.lastName = ''
                }
                if (arrStrNameLastName.length === 2) {
                  this.client.name = arrStrNameLastName[0]
                  this.client.lastName = arrStrNameLastName[1]
                }
                if (arrStrNameLastName.length === 3) {
                  this.client.name = arrStrNameLastName[0]
                  this.client.lastName = arrStrNameLastName[1] + ' ' + arrStrNameLastName[2]
                }
                if (arrStrNameLastName.length === 4) {
                  this.client.name = arrStrNameLastName[0] + ' ' + arrStrNameLastName[1]
                  this.client.lastName = arrStrNameLastName[2] + ' ' + arrStrNameLastName[3]
                }
                if (arrStrNameLastName.length === 5) {
                  this.client.name = arrStrNameLastName[0] + ' ' + arrStrNameLastName[1]
                  this.client.lastName = arrStrNameLastName[2] + ' ' + arrStrNameLastName[3] + ' ' + arrStrNameLastName[4]
                }
              } else {
                this.client.name = this.nameLastName.trim()
                this.client.lastName = ''
              }

              this.client.completeData = this.client.name + ' ' + this.client.lastName + ' | ' + this.client.ide + ' | ' + this.client.mail
              this.client.viewName = this.client.name + ' ' + this.client.lastName
            }
            this.client.mail = this.client.mail.trim()
            this.$store.dispatch('client/setClient', [this.client.clientKey, this.client]).then(async resolve => {
              this.snackbar = {
                show: true,
                color: 'green',
                text: 'Cliente registrado correctamente',
              }
              this.$emit('closeModal')
            }, reject => {
              this.snackbar = {
                show: true,
                color: 'red',
                text: 'Error al registrar el cliente',
              }
            })
          } else {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Verifique el número de identifación agregado.',
            }
          }
        }
      },
    },
  }
</script>
